import React, { Fragment } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasClassicTheme } from 'common/src/theme/saasClassic';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/SaasClassic/saasClassic.style';

import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import Image from 'reusecore/src/elements/Image';

import BannerSection from '../containers/SaasClassic/Banner';
import Navbar from '../containers/SaasClassic/Navbar';
// import ServiceSection from '../containers/SaasClassic/Service';
// import PricingSection from '../containers/SaasClassic/Pricing';
// import PartnerSection from '../containers/SaasClassic/Partner';
// import TrialSection from '../containers/SaasClassic/Trial';
// import FeatureSection from '../containers/SaasClassic/Feature';
// import UpdateScreen from '../containers/SaasClassic/UpdateScreen';
// import TestimonialSection from '../containers/SaasClassic/Testimonial';

import Newsletter from '../containers/SaasClassic/Newsletter';
import Footer from '../containers/SaasClassic/Footer';
import '@redq/reuse-modal/es/index.css';
import SEO from '../components/seo';

// import BlogPostList from 'common/src/components/BlogPostList';
//import BannerImage from 'common/src/assets/image/saasClassic/banner-image.jpg';

// componentDidMount(){
//   //this.stripe = window.Stripe("pk_test_wzNT8MzjQcpEGiEATBCcRnhU")
// }

// const testfunc = async(event) => { console.log('poop') }

const Offer = ({ row, contentWrapper, blogTitle, fillButton }) => {
  const redirectToCheckout = async event => {
    var stripe = window.Stripe('pk_test_wzNT8MzjQcpEGiEATBCcRnhU');

    event.preventDefault();
    const { error } = await stripe.redirectToCheckout({
      items: [{ sku: 'sku_GXf5iK08Xca2nM', quantity: 1 }],
      successUrl: `https://google.com/`,
      cancelUrl: `https://yahoo.com/`,
    });
    if (error) {
      console.warn('Error:', error);
    }
  };

  //let { row, contentWrapper, blogTitle, fillButton } = this.props

  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <SEO title="podstacks | POD2020 PROMO" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <Container className="blog_post_list">
            <Box {...row}>
              <Box {...contentWrapper}>
                <Box {...row}>
                  <Heading {...blogTitle} content="2020 Launchpad Special" />
                </Box>

                <Box {...row}>
                  <Image
                    src="https://neoevolutions.s3-us-west-2.amazonaws.com/pod2020/1.png"
                    alt="banner image"
                  />
                  <Image
                    src="https://neoevolutions.s3-us-west-2.amazonaws.com/pod2020/2.png"
                    alt="banner image"
                  />
                  <Image
                    src="https://neoevolutions.s3-us-west-2.amazonaws.com/pod2020/3.png"
                    alt="banner image"
                  />
                </Box>

                <Box {...row}>
                  {/* <Button {...fillButton} title="Get Started Now"  /> */}
                  <Button
                    {...fillButton}
                    title="Get Started Now"
                    onClick={event => redirectToCheckout(event)}
                  />
                  {/* <Button {...fillButton} title="Get Started Now" onClick={()=>{console.log('poop')} } /> */}
                </Box>
              </Box>
            </Box>
          </Container>

          {/* <BannerSection /> */}
          {/* <ServiceSection /> */}
          {/* <UpdateScreen /> */}
          {/* <FeatureSection /> */}
          {/* <PartnerSection /> */}
          {/* <PricingSection /> */}
          {/* <TestimonialSection /> */}
          {/* <TrialSection /> */}
          {/* <Newsletter /> */}
          {/* <Footer /> */}
          <Newsletter />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

Offer.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '50px',
    mb: '50px',
  },
  contentWrapper: {
    width: ['100%', '100%', '60%', '60%', '60%'],
    mb: '40px',
  },
  blogTitle: {
    fontSize: ['24px', '32px', '40px', '42px', '46px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '28px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
};

export default Offer;
